<template>
    <div>

        <div class="px-2 pt-2">
            <router-link to="/bussiness/shop" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
        </div>

        <!-- BANNER -->
        <div class="mt-2">
            <b-carousel id="carousel-1" img-height="200px" :interval="4000" indicators>
                <b-carousel-slide>
                    <template #img>
                        <img class="w-100" height="200px" style="object-fit: cover;"
                            src="https://storage.googleapis.com/finansialku_media/wordpress_media/2019/10/736225d1-pet-shop-01-finansialku.jpg"
                            alt="image slot">
                    </template>
                </b-carousel-slide>
                <b-carousel-slide>
                    <template #img>
                        <img class="w-100" height="200px" style="object-fit: cover;"
                            src="https://storage.googleapis.com/finansialku_media/wordpress_media/2019/10/736225d1-pet-shop-01-finansialku.jpg"
                            alt="image slot">
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </div>
        <!-- END BANNER -->

        <div class="p-2">
            <div class="d-flex justify-content-between w-100 mb-1" style="gap:10px">
                <h4 class="text-white font-weight-bolder mb-0">Dog Food A</h4>
                <h4 class="text-white font-weight-bolder mb-0">$15</h4>
            </div>
            <div class="d-flex w-100 justify-content-between" style="gap:10px">
                <div class="d-flex align-items-center" style="gap:5px;width: 10% !important;">
                    <i class="bx bxs-star" style="color: #FFAD32;"></i>
                    <div class="text-white">5</div>
                </div>
                <div class="text-white text-center" style="width: 35% !important;">Jakarta</div>
                <div class="text-white text-right" style="width: 40% !important;">Sold: 15000 Items</div>
            </div>
            <p class="mt-1 text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula porttitor magna a porttitor.
                Fusce nibh lectus, faucibus ut imperdiet nec,
            </p>
        </div>

    </div>
</template>

<script>

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
    components: {
        BCarousel, BCarouselSlide
    },
    data() {
        return {

        }
    }
}

</script>